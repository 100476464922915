import React, { FC, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import clsx from 'clsx';
// styles
import styles from './styles.module.css';
// components
import StepButtons from '../../step-buttons';
// hooks
import { useFormState } from 'components/fitness-app-calculator/hooks/useFormState';
// icons
import Ios from './icons/ios.inline.svg';
import Android from './icons/android.inline.svg';
import IosAndAndroid from './icons/ios_and_android.inline.svg';
// constants
import { Step } from 'components/fitness-app-calculator/constants';
//helpers
import { handleGaEvent } from 'utils/helpers';
import { api } from 'utils/api';

interface IProps {
  icon: React.ReactElement;
  text: string;
  active: boolean;
  onClick: () => void;
}

const OSes = [
  {
    text: 'IOS',
    value: 'IOS',
    icon: <Ios />,
  },
  {
    text: 'Android',
    value: 'Android',
    icon: <Android />,
  },
  {
    text: 'Cross-platform app',
    value: 'Cross-platform app',
    icon: <IosAndAndroid />,
  },
];

const Card: FC<IProps> = ({ icon, text, active, onClick }) => (
  <button
    type="button"
    className={clsx(styles.card, { [styles.active]: active })}
    onClick={onClick}
  >
    {icon}
    <p>{text}</p>
  </button>
);

const OS = () => {
  const { updateFormState, formState, totalHours, activeStep } = useFormState();

  const form = useForm<{ OS: string }>({ defaultValues: { OS: formState.OS } });

  const [isLoading, setLoading] = useState(false);

  const osWatch = useWatch({ name: 'OS', control: form.control });

  const onSubmit = async ({ OS }: any) => {
    try {
      setLoading(true);
      if (!formState.id) {
        const {
          data: { id },
        } = await api.strapi.createFitnessAppEstimation({ OS, step: activeStep });
        updateFormState({ id, OS });
        localStorage.setItem('fac_id', id);

        handleGaEvent({
          event: 'formSubmission',
          formType: 'Fitness calculator',
          userId: id,
        });
      }

      if (formState.id) {
        const {
          data: { id },
        } = await api.strapi.updateFitnessAppEstimation(formState.id, {
          OS,
          total_hours: totalHours.toString(),
          step: activeStep,
        });
        updateFormState({ OS });

        handleGaEvent({
          event: 'formSubmission',
          formType: 'Fitness calculator',
          userId: id,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChooseOS = (value: string) => () => {
    form.setValue('OS', value);
  };

  return (
    <FormProvider {...form}>
      <form>
        <div className={styles.container}>
          {OSes.map(({ value, ...item }) => (
            <Card
              key={value}
              {...item}
              onClick={handleChooseOS(value)}
              active={osWatch === value}
            />
          ))}
        </div>
        <StepButtons onSubmit={onSubmit} isLoading={isLoading} />
      </form>
    </FormProvider>
  );
};

export default OS;
