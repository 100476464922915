import React, { useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
// styles
import styles from '../common.module.css';
// components
import { InputField } from 'components/form/field';
import Checkbox from '../../checkbox';
import StepButtons from '../../step-buttons';
// hooks
import { useFormState } from 'components/fitness-app-calculator/hooks/useFormState';
import { api } from 'utils/api';

const fields = [
  {
    label: 'Wearable and non-wearable device integration',
    name: 'additional_features.integrations',
    value: 'Wearable and non-wearable device integration',
    hours: 30,
  },
  {
    label: 'Altimeter',
    name: 'additional_features.integrations',
    value: 'Altimeter',
    hours: 20,
  },
  {
    label: 'Integration with Health/Fitness Data providers',
    name: 'additional_features.integrations',
    value: 'Integration with Health/Fitness Data providers',
    hours: 400,
  },
  {
    label: 'Disease management from',
    name: 'additional_features.integrations',
    value: 'Disease management from',
    hours: 400,
  },
  {
    label: 'Sleep tracker',
    name: 'additional_features.integrations',
    value: 'Sleep tracker',
    hours: 100,
  },
];

const AdditionalFeatures = () => {
  const { nextStep, formState, updateFormState, totalHours, activeStep } = useFormState();

  const form = useForm({
    defaultValues: {
      additional_features: formState.additional_features,
      isAnalitycs: formState.additional_features?.analitycs.length ? 'Yes' : 'No',
    },
  });

  const isAnalitycsWatch = useWatch({
    name: 'isAnalitycs',
    control: form.control,
    defaultValue: formState.additional_features?.analitycs.length ? 'Yes' : 'No',
  });

  console.log(isAnalitycsWatch);

  const [isLoading, setLoading] = useState(false);

  const onSubmit = async ({ additional_features }: any) => {
    try {
      setLoading(true);
      if (formState.id) {
        await api.strapi.updateFitnessAppEstimation(formState.id, {
          additional_features: {
            notifications: additional_features.notifications.join('\n'),
            messages: additional_features.messages.join('\n'),
            integrations: additional_features.integrations.join('\n'),
            analitycs: additional_features.analitycs,
          },
          total_hours: totalHours.toString(),
          step: activeStep,
        });
      }
      updateFormState({ additional_features });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...form}>
      <form>
        <div className={styles.container}>
          <h4 className={styles.subtitle}>Notifications</h4>
          <div className={styles.checkboxContainer}>
            <Checkbox
              label="List of Notifications"
              name="additional_features.notifications"
              value="List of Notifications"
              hours={20}
            />
          </div>

          <h4 className={styles.subtitle}>Messages</h4>
          <div className={styles.checkboxContainer}>
            <Checkbox label="Chats" name="additional_features.messages" value="Chats" hours={50} />
          </div>
          <div className={styles.checkboxContainer}>
            <Checkbox
              label="Send photo"
              name="additional_features.messages"
              value="Send photo"
              hours={20}
            />
          </div>

          <h4 className={styles.subtitle}>Integrations</h4>
          {fields.map((item) => (
            <div key={item.value} className={styles.checkboxContainer}>
              <Checkbox {...item} />
            </div>
          ))}

          <h4 className={styles.subtitle}>Analitycs</h4>
          <div className={styles.checkboxContainer}>
            <Checkbox
              label="Analytics and predictions (explain your idea)"
              name="isAnalitycs"
              value="Yes"
              onChange={(e) => {
                if (e.target.value === isAnalitycsWatch) {
                  if (e.target.value === 'Yes') {
                    form.setValue('additional_features.analitycs', '');
                    form.setValue('isAnalitycs', 'No');
                    return;
                  }
                  if (e.target.value === 'No') {
                    form.setValue('isAnalitycs', 'Yes');
                    return;
                  }
                }

                form.setValue('isAnalitycs', e.target.value);
              }}
            />
          </div>
          <div>
            <InputField
              disabled={isAnalitycsWatch === 'No'}
              name="additional_features.analitycs"
              placeholder="Type something..."
            />
          </div>
        </div>
        <StepButtons onSubmit={onSubmit} isLoading={isLoading} />
      </form>
    </FormProvider>
  );
};

export default AdditionalFeatures;
