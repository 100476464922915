import React, { FC, useState } from 'react';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import clsx from 'clsx';
// styles
import styles from './styles.module.css';
// components
import StepButtons from '../../step-buttons';
// api
import { api } from 'utils/api';
// icons
import Clock from './hours.inline.svg';
// hooks
import { useFormState } from 'components/fitness-app-calculator/hooks/useFormState';

interface IProps {
  name: string;
  label: string;
  value: string;
  hours?: number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export const Radio: FC<IProps> = ({ name, label, value, hours, onChange }) => {
  const { register, control } = useFormContext();

  const { onChange: formOnChange, ...formRegister } = register(name);

  const radioWatch = useWatch({ name, control });

  return (
    <div>
      <label className={styles.containerRadio}>
        <input
          type="radio"
          style={{ display: 'none' }}
          value={value}
          {...formRegister}
          onChange={onChange || formOnChange}
        />
        <div className={clsx(styles.radio, { [styles.active]: radioWatch === value })}>
          <div className={styles.checked} />
        </div>
        <div className={styles.label}>{label}</div>
        {hours && (
          <div className={styles.hours}>
            <Clock />
            <span>{hours}h</span>
          </div>
        )}
      </label>
    </div>
  );
};

const field = [
  {
    label: 'None',
    name: 'need_design',
    value: 'None',
  },
  {
    label: 'Simple',
    name: 'need_design',
    value: 'Simple',
    hours: 96,
  },
  {
    label: 'Custom',
    name: 'need_design',
    value: 'Custom',
    hours: 288,
  },
];

const NeedDesign = () => {
  const { formState, updateFormState, totalHours, activeStep } = useFormState();

  const form = useForm({
    defaultValues: { need_design: formState.need_design },
  });

  const [isLoading, setLoading] = useState(false);

  const onSubmit = async ({ need_design }: any) => {
    try {
      setLoading(true);
      if (formState.id) {
        await api.strapi.updateFitnessAppEstimation(formState.id, {
          need_design,
          total_hours: totalHours.toString(),
          step: activeStep,
        });
      }
      updateFormState({ need_design });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...form}>
      <form>
        <div className={styles.container}>
          {field.map((item) => (
            <div key={item.value} className={styles.checkboxContainer}>
              <Radio {...item} />
            </div>
          ))}
        </div>
        <StepButtons onSubmit={onSubmit} isLoading={isLoading} />
      </form>
    </FormProvider>
  );
};

export default NeedDesign;
