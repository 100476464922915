import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
// styles
import styles from '../common.module.css';
// components
import Checkbox from '../../checkbox';
import StepButtons from '../../step-buttons';
// hooks
import { useFormState } from 'components/fitness-app-calculator/hooks/useFormState';
import { api } from 'utils/api';

const fields = [
  {
    label: 'Braintree',
    name: 'payments.additional_features',
    value: 'Braintree',
    hours: 53,
  },
  {
    label: 'PayPal',
    name: 'payments.additional_features',
    value: 'PayPal',
    hours: 49,
  },
  {
    label: 'Dwolla',
    name: 'payments.additional_features',
    value: 'Dwolla',
    hours: 53,
  },
  {
    label: 'Authorize.net',
    name: 'payments.additional_features',
    value: 'Authorize.net',
    hours: 53,
  },
];

const Payments = () => {
  const { nextStep, formState, updateFormState, totalHours, activeStep } = useFormState();

  const form = useForm({
    defaultValues: { payments: formState.payments },
  });

  const [isLoading, setLoading] = useState(false);

  const onSubmit = async ({ payments }: any) => {
    try {
      setLoading(true);

      if (formState.id) {
        await api.strapi.updateFitnessAppEstimation(formState.id, {
          payments: {
            additional_features: payments.additional_features.join('\n'),
            enough_for_mvp: payments.enough_for_mvp.join('\n'),
          },
          total_hours: totalHours.toString(),
          step: activeStep,
        });
      }
      updateFormState({ payments });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...form}>
      <form>
        <div className={styles.container}>
          <h4 className={styles.subtitle}>Enough for MVP</h4>

          <div className={styles.checkboxContainer}>
            <Checkbox
              label="Stripe"
              name="payments.enough_for_mvp"
              value="Stripe"
              hours={40}
              tip="Stripe is supported in the following countries:<br />
    Australia, Austria, Belgium, Canada, Denmark, Finland, France, Germany, <br />
    Hong Kong, Ireland, Japan, Luxembourg, Netherlands, New Zealand, Norway, <br />
    Singapore, Spain, Sweden, Switzerland, United Kingdom, United States, Italy, Portugal<br />"
            />
          </div>

          <h4 className={styles.subtitle}>Additional features</h4>

          {fields.map((item) => (
            <div key={item.value} className={styles.checkboxContainer}>
              <Checkbox {...item} />
            </div>
          ))}
        </div>
        <StepButtons onSubmit={onSubmit} isLoading={isLoading} />
      </form>
    </FormProvider>
  );
};

export default Payments;
