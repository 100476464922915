import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
// styles
import styles from './form-layout.module.css';
//components
import { Step } from 'components/fitness-app-calculator/constants';
import { useFormState } from 'components/fitness-app-calculator/hooks/useFormState';

interface IProps {
  title: string;
  subtitle?: string;
}

const FormLayout: FC<IProps> = ({ title, subtitle, children }) => {
  const { totalHours, activeStep } = useFormState();

  const [isMobile, setIsMobile] = useState(false);

  const onResize = () => setIsMobile(window.innerWidth < 1025);

  useEffect(() => {
    setIsMobile(window.innerWidth < 1025);

    window.addEventListener('resize', onResize, false);

    return () => {
      window.removeEventListener('resize', onResize, false);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: isMobile ? 150 : 300, behavior: 'smooth' });
  }, [activeStep, isMobile]);

  return (
    <div className={styles.container}>
      <div className={clsx(styles.form, { [styles.fullWidth]: activeStep === Step.SummaryTime })}>
        <h2 className={styles.title}>{title}</h2>
        {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
        {children}
      </div>
      {activeStep !== Step.SummaryTime && (
        <div className={styles.time}>
          <div>
            <div className={styles.timeTitle}>Time at{'\n'} this stage</div>
            <div className={styles.timeCount}>
              {totalHours} <span className={styles.hours}>hours</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormLayout;
