import React, { useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
// styles
import styles from './styles.module.css';
// components
import Checkbox from '../../checkbox';
import StepButtons from '../../step-buttons';
// hooks
import { useFormState } from 'components/fitness-app-calculator/hooks/useFormState';
import { api } from 'utils/api';

const fields = [
  {
    label: 'via Email',
    name: 'registration_and_authorization',
    value: 'via Email',
    hours: 5,
  },
  {
    label: 'via Phone',
    name: 'registration_and_authorization',
    value: 'via Phone',
    hours: 5,
  },
  {
    label: 'via Facebook',
    name: 'registration_and_authorization',
    value: 'via Facebook',
    hours: 12,
  },
  {
    label: 'via Google+',
    name: 'registration_and_authorization',
    value: 'via Google+',
    hours: 12,
  },
];

const RegistrationAndAuthorization = () => {
  const { nextStep, updateFormState, formState, totalHours, activeStep } = useFormState();

  const form = useForm<{ registration_and_authorization: string[] }>({
    defaultValues: { registration_and_authorization: formState.registration_and_authorization },
  });

  const regAndAuthWatch = useWatch({
    name: 'registration_and_authorization',
    control: form.control,
    defaultValue: formState.registration_and_authorization,
  });

  const [isLoading, setLoading] = useState(false);

  const onSubmit = async ({ registration_and_authorization }: any) => {
    try {
      setLoading(true);
      if (formState.id) {
        await api.strapi.updateFitnessAppEstimation(formState.id, {
          registration_and_authorization: registration_and_authorization.join('\n'),
          total_hours: totalHours.toString(),
          step: activeStep,
        });
      }

      updateFormState({ registration_and_authorization });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...form}>
      <form>
        <div className={styles.container}>
          {fields.map((item) => (
            <div key={item.value} className={styles.checkboxContainer}>
              <Checkbox {...item} />
            </div>
          ))}
        </div>
        <StepButtons
          onSubmit={onSubmit}
          disableNextBtn={regAndAuthWatch.length === 0}
          isLoading={isLoading}
        />
      </form>
    </FormProvider>
  );
};

export default RegistrationAndAuthorization;
