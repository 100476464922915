import React from 'react';
import AbleViewWorkout from './components/forms/able-view-workout';
// components
import ActivityTracking from './components/forms/activity-tracking';
import AdditionalFeatures from './components/forms/additional-features';
import AdditionalInformation from './components/forms/additional-information';
import FitnessClubManagement from './components/forms/fitness-club-management';
import NeedDesign from './components/forms/need-design';
import Nutrition from './components/forms/nutrition';
import OS from './components/forms/os';
import Payments from './components/forms/payments';
import RegAuth from './components/forms/registration-and-authorization';
import SocialNetwork from './components/forms/social-network';
import SummaryTime from './components/forms/summary-time';
import Type from './components/forms/type';
import UserProfile from './components/forms/user-profile';
import WorkoutAndExercise from './components/forms/workout-and-exercise';

export enum Step {
  OS = 'OS',
  Type = 'Type',
  RegAuth = 'RegAuth',
  UserProfile = 'UserProfile',
  Payments = 'Payments',
  FitnessClubManagement = 'FitnessClubManagement',
  AbleViewWorkout = 'AbleViewWorkout',
  SocialNetwork = 'SocialNetwork',
  Nutrition = 'Nutrition',
  WorkoutAndExercise = 'WorkoutAndExercise',
  ActivityTracking = 'ActivityTracking',
  AdditionalFeatures = 'AdditionalFeatures',
  AdditionalInformation = 'AdditionalInformation',
  NeedDesign = 'NeedDesign',
  SummaryTime = 'SummaryTime',
}

export interface IMetaData {
  title: string;
  subtitle: string;
}

export interface IForm {
  step: Step;
  metaData: IMetaData;
  Component: React.FC;
}

export const defaultSteps = [
  Step.OS,
  Step.Type,
  Step.RegAuth,
  Step.UserProfile,
  Step.Payments,
  Step.SocialNetwork,
  Step.AdditionalFeatures,
  Step.AdditionalInformation,
  Step.NeedDesign,
  Step.SummaryTime,
];

export const formMetaData = {
  OS: {
    title: 'What OS are you going to build app for?',
    subtitle: 'Please choose the OS below.',
  },
  type: {
    title: 'Choose a type of fitness app',
    subtitle:
      'Select one or more options.\nThis will affect the set of future application features.',
  },
  regAuth: {
    title: 'Registration & Authorization',
    subtitle: 'How users will sign up/login to your app?',
  },
  userProfile: {
    title: 'User’s Profile',
    subtitle: 'Please choose the required features below',
  },
  payments: {
    title: 'Payments',
    subtitle: 'Please choose payment system integration for your app',
  },
  fitnessClubManagement: {
    title: 'Fitness club management',
    subtitle: 'Please choose the required features below',
  },
  socialNetwork: {
    title: 'Social Network',
  },
  nutrition: {
    title: 'Nutrition',
    subtitle: 'Please choose the required features below',
  },
  workoutAndExercise: {
    title: 'Workout and exercise',
  },
  activityTracking: {
    title: 'Activity tracking',
  },
  additionalFeatures: {
    title: 'Additional features',
  },
  additionalInformation: {
    title: 'Additional Information',
    subtitle:
      'You might want to add something.\nThis will greatly help to make the estimate more accurate.',
  },
  needDesign: {
    title: 'Do you need a design for your App?',
  },
  summaryTime: {
    title: 'Summary Time',
    subtitle:
      'You can see the approximate number of hours required to develop the selected feature. The estimation was made on the basis of their experience of our previous projects',
  },
  ableViewWorkout: {
    title: 'Will users be able to view workout plans and results in the app?',
  },
};

export const forms = [
  {
    step: Step.OS,
    metaData: formMetaData.OS,
    Component: OS,
  },
  {
    step: Step.Type,
    metaData: formMetaData.type,
    Component: Type,
  },
  {
    step: Step.RegAuth,
    metaData: formMetaData.regAuth,
    Component: RegAuth,
  },
  {
    step: Step.UserProfile,
    metaData: formMetaData.userProfile,
    Component: UserProfile,
  },
  {
    step: Step.Payments,
    metaData: formMetaData.payments,
    Component: Payments,
  },
  {
    step: Step.FitnessClubManagement,
    metaData: formMetaData.fitnessClubManagement,
    Component: FitnessClubManagement,
  },
  {
    step: Step.SocialNetwork,
    metaData: formMetaData.socialNetwork,
    Component: SocialNetwork,
  },
  {
    step: Step.Nutrition,
    metaData: formMetaData.nutrition,
    Component: Nutrition,
  },
  {
    step: Step.WorkoutAndExercise,
    metaData: formMetaData.workoutAndExercise,
    Component: WorkoutAndExercise,
  },
  {
    step: Step.ActivityTracking,
    metaData: formMetaData.activityTracking,
    Component: ActivityTracking,
  },
  {
    step: Step.AdditionalFeatures,
    metaData: formMetaData.additionalFeatures,
    Component: AdditionalFeatures,
  },
  {
    step: Step.AdditionalInformation,
    metaData: formMetaData.additionalInformation,
    Component: AdditionalInformation,
  },
  {
    step: Step.NeedDesign,
    metaData: formMetaData.needDesign,
    Component: NeedDesign,
  },
  {
    step: Step.SummaryTime,
    metaData: formMetaData.summaryTime,
    Component: SummaryTime,
  },
  {
    step: Step.AbleViewWorkout,
    metaData: formMetaData.ableViewWorkout,
    Component: AbleViewWorkout,
  },
];
