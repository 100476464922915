import React, { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';
// styles
import styles from './styles.module.css';
// components
import CustomCheckbox from 'components/checkbox';
// hooks
import { useFormState } from 'components/fitness-app-calculator/hooks/useFormState';
// icons
import Clock from './hours.inline.svg';

interface IProps {
  label: string;
  name: string;
  value: any;
  hours?: number;
  onClickHelper?: (value: string) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  tip?: string;
}

const Checkbox: FC<IProps> = ({ label, name, value, hours, onClickHelper, onChange, tip }) => {
  const { control, setValue } = useFormContext();

  const { updateHours } = useFormState();

  const fieldWatch = useWatch({ name, control });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (fieldWatch?.includes(e.target.value)) {
      if (hours) {
        updateHours(-hours);
      }
      setValue(name, [...fieldWatch.filter((item: string) => item !== value)]);
      onClickHelper?.(value);
      return;
    }

    if (hours) {
      updateHours(hours);
    }

    setValue(name, [...fieldWatch, value]);
    onClickHelper?.(value);
  };

  return (
    <>
      <label className={styles.container} data-place="right" data-tip={tip}>
        <CustomCheckbox
          onChange={onChange || handleChange}
          checked={fieldWatch?.includes(value)}
          value={value}
        />
        <div className={styles.label}>{label}</div>
        {hours && (
          <div className={styles.hours}>
            <Clock />
            <span>{hours}h</span>
          </div>
        )}
      </label>
      <ReactTooltip
        place="bottom"
        type="light"
        effect="solid"
        multiline
        className={styles.tooltip}
      />
    </>
  );
};

export default Checkbox;
