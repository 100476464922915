import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';
// constants
import { defaultSteps, Step } from '../constants';

export interface IFormState {
  id?: number | string;
  OS?: string;
  type?: string[];
  registration_and_authorization?: string[];
  user_profile?: string[];
  payments?: {
    additional_features?: string[];
    enough_for_mvp?: string[];
  };
  fitness_club_management?: string[];
  social_network?: {
    friends?: string[];
    followers?: string[];
    posts?: string[];
  };
  nutrition?: string[];
  workout_and_exercise?: {
    workout_plan?: string[];
    workout_tracking?: string[];
    recording_results?: string;
  };
  activity_tracking?: string[];
  additional_features?: {
    notifications: string[];
    messages: string[];
    integrations: string[];
    analitycs: string;
  };
  additional_information?: string;
  need_design?: string;
  full_name?: string;
  email?: string;
  ableViewWorkout?: string;
}

export const defaultValues: IFormState = {
  OS: 'Cross-platform app',
  type: [],
  registration_and_authorization: [],
  user_profile: [],
  payments: {
    additional_features: [],
    enough_for_mvp: [],
  },
  fitness_club_management: [],
  social_network: {
    friends: [],
    followers: [],
    posts: [],
  },
  nutrition: [],
  workout_and_exercise: {
    workout_plan: [],
    workout_tracking: [],
    recording_results: '',
  },
  activity_tracking: [],
  additional_features: {
    notifications: [],
    messages: [],
    integrations: [],
    analitycs: '',
  },
  additional_information: '',
  need_design: 'None',
  full_name: '',
  email: '',
  ableViewWorkout: 'No',
};

interface IStepperContext {
  steps: Step[];
  totalHours: number;
  activeStep: Step;
  formState: IFormState;
  setSteps: React.Dispatch<React.SetStateAction<Step[]>>;
  setActiveStep: React.Dispatch<React.SetStateAction<Step>>;
  addStep: (step: Step, after?: Step) => void;
  removeStep: (step: Step) => void;
  nextStep: () => void;
  prevStep: () => void;
  updateHours: (hours: number) => void;
  updateFormState: (data: IFormState) => void;
}

const StepperContext = createContext<IStepperContext>({} as IStepperContext);
export const useFormState = () => useContext(StepperContext);

export const StepperProvider: FC = ({ children }) => {
  const [totalHours, setTotalHours] = useState(0);
  const [steps, setSteps] = useState(defaultSteps);
  const [activeStep, setActiveStep] = useState(defaultSteps[0]);
  const [formState, setFormState] = useState<IFormState>(defaultValues);

  useEffect(() => {
    if (localStorage) {
      localStorage.setItem('fac_steps', JSON.stringify(steps));
    }
  }, [steps]);

  const addStep = (step: Step, after?: Step) => {
    setSteps((prevSteps) => {
      const copyPrevSteps = [...prevSteps];
      copyPrevSteps.splice(prevSteps.indexOf(after || Step.Payments) + 1, 0, step);
      return copyPrevSteps;
    });
  };

  const removeStep = (step: Step) => {
    setSteps((prevSteps) => prevSteps.filter((item) => item !== step));
  };

  const nextStep = () => {
    setActiveStep((prevStep) => {
      return steps[steps.indexOf(prevStep) + 1];
    });
  };

  const prevStep = () => {
    setActiveStep((prevStep) => {
      return steps[steps.indexOf(prevStep) - 1];
    });
  };

  const updateFormState = (data: IFormState) => {
    setFormState((prev) => ({ ...prev, ...data }));
  };

  const updateHours = (hours: number) => {
    setTotalHours((prev) => prev + hours);
  };

  return (
    <StepperContext.Provider
      value={{
        totalHours,
        formState,
        steps,
        activeStep,
        nextStep,
        prevStep,
        setSteps,
        setActiveStep,
        addStep,
        removeStep,
        updateHours,
        updateFormState,
      }}
    >
      {children}
    </StepperContext.Provider>
  );
};
