import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
// styles
import styles from '../common.module.css';
// components
import { TextAreaField } from 'components/form/field';
import StepButtons from '../../step-buttons';
// hooks
import { useFormState } from 'components/fitness-app-calculator/hooks/useFormState';
import { api } from 'utils/api';

const AdditionalInformation = () => {
  const { nextStep, formState, updateFormState, totalHours, activeStep } = useFormState();

  const form = useForm({
    defaultValues: { additional_information: formState.additional_information },
  });

  const [isLoading, setLoading] = useState(false);

  const onSubmit = async ({ additional_information }: any) => {
    try {
      setLoading(true);
      if (formState.id) {
        await api.strapi.updateFitnessAppEstimation(formState.id, {
          additional_information: additional_information,
          total_hours: totalHours.toString(),
          step: activeStep,
        });
      }
      updateFormState({ additional_information });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...form}>
      <form>
        <div className={styles.container}>
          <TextAreaField name="additional_information" placeholder="Type something..." />
        </div>
        <StepButtons onSubmit={onSubmit} isLoading={isLoading} />
      </form>
    </FormProvider>
  );
};

export default AdditionalInformation;
