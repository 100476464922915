import React, { FC } from 'react';
import './spinner.css';

const Spinner: FC<{ color?: string; width?: number; height?: number }> = ({
  color,
  width,
  height,
}) => {
  return (
    <div className="lds-ring" style={{ width, height }}>
      <div
        style={{ borderColor: `${color} transparent transparent transparent`, width, height }}
      ></div>
      <div
        style={{ borderColor: `${color} transparent transparent transparent`, width, height }}
      ></div>
      <div
        style={{ borderColor: `${color} transparent transparent transparent`, width, height }}
      ></div>
      <div
        style={{ borderColor: `${color} transparent transparent transparent`, width, height }}
      ></div>
    </div>
  );
};

export default Spinner;
