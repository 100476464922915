import React, { useEffect, useState } from 'react';
// constants
import { meta } from 'metaData';
import { Step } from './constants';
// hooks
import { useFormState } from './hooks/useFormState';
// helpers
import { api } from 'utils/api';
import { createDefaultData, getFormDataByStep, withStepperContext } from './helpers';
// styles
import styles from './fitness-app-calculator.module.css';
// components
import Layout from 'components/layout';
import FormLayout from './components/form-layout';
import Footer from 'components/layout/footer';
import Spinner from './components/spinner';

const FitnessAppCalculator = () => {
  const { activeStep, updateFormState, setSteps, setActiveStep, updateHours } = useFormState();

  const { Component, metaData } = getFormDataByStep(activeStep);

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      const id = localStorage.getItem('fac_id');
      const steps = localStorage.getItem('fac_steps');

      if (id) {
        try {
          setLoading(true);
          const { data } = await api.strapi.getFitnessAppEstimation(id);
          updateFormState({ ...createDefaultData(data) });
          setActiveStep(data.step as Step);
          updateHours(Number(data.total_hours));
          if (steps) {
            setSteps(JSON.parse(steps));
          }
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      }
    };

    loadData();
  }, []);

  return (
    <Layout navBtnTextColor="#D91965" metaData={{ main: meta.fitnessAppCalculator }} isRed>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.title}>Fitness app calculator</h1>
        </div>
        <div className={styles.form}>
          {isLoading ? (
            <FormLayout title="">
              <div className={styles.spinnerContainer}>
                <Spinner color="#d91965" width={60} height={60} />
              </div>
            </FormLayout>
          ) : (
            <FormLayout {...metaData}>{Component && <Component />}</FormLayout>
          )}
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default withStepperContext(FitnessAppCalculator);
