type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;
interface IGaEvent {
  event: string;
  formType: string;
  userId: string;
}
export const handleGaEvent = ({ event, formType, userId }: IGaEvent) => {
  if (typeof window === 'undefined') {
    return;
  }
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event,
    formType,
    userId,
  });
};
export const scrollIntoViewWithOffset = (selector: string, offset: number) => {
  const elementTop = document?.querySelector(selector)?.getBoundingClientRect()?.top;
  const bodyLenght = document?.body?.getBoundingClientRect()?.top;
  const scrollingLenght = elementTop && bodyLenght ? elementTop - bodyLenght - offset : 0;

  window.scrollTo({
    behavior: 'smooth',
    top: scrollingLenght,
  });
};
