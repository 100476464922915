import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
// styles
import styles from '../common.module.css';
// components
import Checkbox from '../../checkbox';
import StepButtons from '../../step-buttons';
// hooks
import { useFormState } from 'components/fitness-app-calculator/hooks/useFormState';
import { api } from 'utils/api';

const fields = [
  {
    label: 'The barcode scanner',
    name: 'nutrition',
    value: 'The application is used by visitors to enter the gym',
    hours: 60,
  },
  {
    label: 'Nutrition and recipes database',
    name: 'nutrition',
    value: 'The app allows visitors to make purchases at the gym',
    hours: 120,
  },
  {
    label: 'Customized Diet Plans',
    name: 'nutrition',
    value: 'Customized Diet Plans',
    hours: 80,
  },
  {
    label: 'Food tracker',
    name: 'nutrition',
    value: 'Food tracker',
    hours: 40,
  },
  {
    label: 'Water tracker',
    name: 'nutrition',
    value: 'Water tracker',
    hours: 40,
  },
  {
    label: 'Food calorie database',
    name: 'nutrition',
    value: 'Food calorie database',
    hours: 80,
  },
];

const Nutrition = () => {
  const { nextStep, formState, updateFormState, totalHours, activeStep } = useFormState();

  const form = useForm({ defaultValues: { nutrition: formState.nutrition } });

  const [isLoading, setLoading] = useState(false);

  const onSubmit = async ({ nutrition }: any) => {
    try {
      setLoading(true);
      if (formState.id) {
        await api.strapi.updateFitnessAppEstimation(formState.id, {
          nutrition: nutrition.join('\n'),
          total_hours: totalHours.toString(),
          step: activeStep,
        });
      }
      updateFormState({ nutrition });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...form}>
      <form>
        <div className={styles.container}>
          {fields.map((item) => (
            <div key={item.value} className={styles.checkboxContainer}>
              <Checkbox {...item} />
            </div>
          ))}
        </div>
        <StepButtons onSubmit={onSubmit} isLoading={isLoading} />
      </form>
    </FormProvider>
  );
};

export default Nutrition;
