import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { navigate } from 'gatsby';
import { yupResolver } from '@hookform/resolvers/yup';
// styles
import clsx from 'clsx';
import styles from './styles.module.css';
// components
import StepButtons from '../../step-buttons';
import { InputField } from 'components/form/field';
import ResponseMessage from 'components/form/response-message';
// helpers
import { api } from 'utils/api';
import { schema } from './validation';
// hooks
import useModal from 'hooks/useModal';
import { useFormState } from 'components/fitness-app-calculator/hooks/useFormState';

const Routes = require('../../../../../components/routes').Routes;

interface IFormData {
  full_name: string;
  email: string;
}

const SummaryTime = () => {
  const { handleModal } = useModal();

  const { formState, totalHours, updateFormState } = useFormState();

  const form = useForm<IFormData>({
    defaultValues: { full_name: formState.full_name, email: formState.email },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: IFormData) => {
    try {
      if (formState.id) {
        await api.strapi.updateFitnessAppEstimation(formState.id, {
          ...data,
          total_hours: totalHours.toString(),
          step: 'completed',
        });
      }
      updateFormState({ ...data });
      localStorage.removeItem('fac_id');
      localStorage.removeItem('fac_steps');
      handleModal(
        <ResponseMessage
          iconName="send-message"
          isLoading={false}
          message={'Your message has been \n sent successfully'}
          close={() => {
            navigate(Routes.FITNESS_MOBILE_SOLUTIONS_B);
            handleModal();
          }}
        />,
        false,
        5000
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.time}>
              <div>
                <p className={styles.caption}>Summary time is</p>
                <div>
                  <span className={styles.hours}>{totalHours}</span> hours
                </div>
              </div>
              <div>
                <p>
                  <span className={styles.caption}>min</span>{' '}
                  <span className={styles.calculatedHours}>{Math.round(totalHours * 0.7)}</span>
                </p>
                <p>
                  <span className={styles.caption}>max</span>{' '}
                  <span className={styles.calculatedHours}>{Math.round(totalHours * 1.3)}</span>
                </p>
              </div>
            </div>
            <div className={styles.fieldsContainer}>
              <p className={styles.formText}>
                Please send your request for a more accurate assessment made by our specialist,
                taking into account your comments, and the project implementation plan
              </p>
              <InputField
                name="full_name"
                label="Full name*"
                placeholder="Type something..."
                error={form.formState.errors.full_name}
              />
              <InputField
                name="email"
                label="Email*"
                placeholder="Type something..."
                error={form.formState.errors.email}
              />
              <button className={clsx('btn btn__black_red', styles.btn)} type="submit">
                Get estimation
              </button>
            </div>
          </div>
        </div>
        <StepButtons onSubmit={onSubmit} hideNextBtn />
      </form>
    </FormProvider>
  );
};

export default SummaryTime;
