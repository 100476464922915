import React, { useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
// styles
import styles from '../common.module.css';
// components
import Checkbox from '../../checkbox';
import StepButtons from '../../step-buttons';
import { InputField } from 'components/form/field';
// hooks
import { useFormState } from 'components/fitness-app-calculator/hooks/useFormState';
import { api } from 'utils/api';

const workOutPlanFields = [
  {
    label: 'Setup plan flow',
    name: 'workout_and_exercise.workout_plan',
    value: 'Setup plan flow',
    hours: 32,
  },
  {
    label: 'Upload photo',
    name: 'workout_and_exercise.workout_plan',
    value: 'Upload photo',
    hours: 20,
  },
  {
    label: 'Upload video/audio',
    name: 'workout_and_exercise.workout_plan',
    value: 'Upload video/audio',
    hours: 35,
  },
  {
    label: 'Live Streaming',
    name: 'workout_and_exercise.workout_plan',
    value: 'Live Streaming',
    hours: 80,
  },
];

const workOutTrackingFields = [
  {
    label: 'List of workout categories',
    name: 'workout_and_exercise.workout_tracking',
    value: 'List of workout categories',
    hours: 15,
  },
  {
    label: 'List of completed and missed workouts',
    name: 'workout_and_exercise.workout_tracking',
    value: 'List of completed and missed workouts',
    hours: 15,
  },
];

const WorkoutAndExercise = () => {
  const { nextStep, formState, updateFormState, totalHours, activeStep, updateHours } =
    useFormState();

  const form = useForm({
    defaultValues: {
      workout_and_exercise: formState.workout_and_exercise,
      isRecording: formState.workout_and_exercise?.recording_results?.length ? 'Yes' : 'No',
    },
  });

  const isRecordingWatch = useWatch({
    name: 'isRecording',
    control: form.control,
    defaultValue: formState.workout_and_exercise?.recording_results?.length ? 'Yes' : 'No',
  });

  const [isLoading, setLoading] = useState(false);

  const onSubmit = async ({ workout_and_exercise }: any) => {
    try {
      setLoading(true);
      if (formState.id) {
        await api.strapi.updateFitnessAppEstimation(formState.id, {
          workout_and_exercise: {
            workout_plan: workout_and_exercise.workout_plan.join('\n'),
            workout_tracking: workout_and_exercise.workout_tracking.join('\n'),
            recording_results: workout_and_exercise.recording_results,
          },
          total_hours: totalHours.toString(),
          step: activeStep,
        });
      }
      updateFormState({ workout_and_exercise });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...form}>
      <form>
        <div className={styles.container}>
          <h4 className={styles.subtitle}>Workout Plan</h4>
          {workOutPlanFields.map((item) => (
            <div key={item.value} className={styles.checkboxContainer}>
              <Checkbox {...item} />
            </div>
          ))}

          <h4 className={styles.subtitle}>Workout tracking</h4>
          {workOutTrackingFields.map((item) => (
            <div key={item.value} className={styles.checkboxContainer}>
              <Checkbox {...item} />
            </div>
          ))}
          <div className={styles.checkboxContainer}>
            <Checkbox
              label="Recording results (automatically or manually? explain your idea)"
              name="isRecording"
              value="Yes"
              onChange={(e) => {
                if (e.target.value === isRecordingWatch) {
                  updateHours(-80);
                  if (e.target.value === 'Yes') {
                    form.setValue('workout_and_exercise.recording_results', '');
                    form.setValue('isRecording', 'No');
                    return;
                  }
                  if (e.target.value === 'No') {
                    form.setValue('isRecording', 'Yes');
                    return;
                  }
                }
                updateHours(80);
                form.setValue('isRecording', e.target.value);
              }}
              hours={80}
            />
          </div>
          <div>
            <InputField
              name="workout_and_exercise.recording_results"
              placeholder="Type something..."
              disabled={isRecordingWatch === 'No'}
            />
          </div>
        </div>
        <StepButtons onSubmit={onSubmit} isLoading={isLoading} />
      </form>
    </FormProvider>
  );
};

export default WorkoutAndExercise;
