import { Step } from 'components/fitness-app-calculator/constants';
import { useFormState } from 'components/fitness-app-calculator/hooks/useFormState';
import React, { useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { api } from 'utils/api';
// styles
import styles from './styles.module.css';
// components
import Checkbox from '../../checkbox';
import StepButtons from '../../step-buttons';

const fields = [
  {
    label: 'Workout and exercise app',
    name: 'type',
    value: 'Workout and exercise app',
  },
  {
    label: 'Nutrition apps',
    name: 'type',
    value: 'Nutrition apps',
  },
  {
    label: 'Activity tracking apps',
    name: 'type',
    value: 'Activity tracking apps',
  },
  {
    label: 'Applications for fitness club',
    name: 'type',
    value: 'Applications for fitness club',
  },
];

const Type = () => {
  const {
    formState,
    nextStep,
    addStep,
    removeStep,
    updateFormState,
    totalHours,
    activeStep,
    steps,
  } = useFormState();

  const form = useForm<{ type: string[] }>({ defaultValues: { type: formState.type } });

  const [isLoading, setLoading] = useState(false);

  const typeWatch = useWatch({ name: 'type', control: form.control, defaultValue: formState.type });

  const onSubmit = async ({ type }: any) => {
    try {
      setLoading(true);
      if (formState.id) {
        await api.strapi.updateFitnessAppEstimation(formState.id, {
          type: type.join('\n'),
          total_hours: totalHours.toString(),
          step: activeStep,
        });
      }
      updateFormState({ type });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddSteps = (value: string) => {
    if (value === 'Workout and exercise app') {
      if (typeWatch.includes(value)) {
        removeStep(Step.WorkoutAndExercise);
        if (steps.includes(Step.FitnessClubManagement)) {
          addStep(Step.AbleViewWorkout, Step.FitnessClubManagement);
        }
        return;
      }
      if (steps.includes(Step.AbleViewWorkout)) {
        removeStep(Step.AbleViewWorkout);
      }

      if (steps.includes(Step.FitnessClubManagement)) {
        addStep(Step.WorkoutAndExercise, Step.FitnessClubManagement);
        return;
      }

      addStep(Step.WorkoutAndExercise);
    }
    if (value === 'Nutrition apps') {
      if (typeWatch.includes(value)) {
        removeStep(Step.Nutrition);
        return;
      }
      addStep(Step.Nutrition);
    }
    if (value === 'Activity tracking apps') {
      if (typeWatch.includes(value)) {
        removeStep(Step.ActivityTracking);
        return;
      }
      addStep(Step.ActivityTracking);
    }
    if (value === 'Applications for fitness club') {
      if (typeWatch.includes(value)) {
        removeStep(Step.FitnessClubManagement);
        if (steps.includes(Step.AbleViewWorkout)) {
          removeStep(Step.AbleViewWorkout);
        }
        return;
      }
      addStep(Step.FitnessClubManagement);
      if (!steps.includes(Step.WorkoutAndExercise)) {
        addStep(Step.AbleViewWorkout, Step.FitnessClubManagement);
      }
    }
  };

  return (
    <FormProvider {...form}>
      <form>
        <div className={styles.container}>
          {fields.map((item) => (
            <div key={item.value} className={styles.checkboxContainer}>
              <Checkbox {...item} onClickHelper={handleAddSteps} />
            </div>
          ))}
        </div>
        <StepButtons
          onSubmit={onSubmit}
          disableNextBtn={typeWatch.length === 0}
          isLoading={isLoading}
        />
      </form>
    </FormProvider>
  );
};

export default Type;
