import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
// styles
import styles from '../common.module.css';
// components
import Checkbox from '../../checkbox';
import StepButtons from '../../step-buttons';
// hooks
import { useFormState } from 'components/fitness-app-calculator/hooks/useFormState';
import { api } from 'utils/api';

const friendsFields = [
  {
    label: 'List of users 24',
    name: 'social_network.friends',
    value: 'List of users 24',
    hours: 24,
  },
  {
    label: 'Invite / Add / Delete user',
    name: 'social_network.friends',
    value: 'Invite / Add / Delete user',
    hours: 16,
  },
];

const followersFields = [
  {
    label: 'List of following',
    name: 'social_network.followers',
    value: 'List of following',
    hours: 15,
  },
  {
    label: 'List of followers',
    name: 'social_network.followers',
    value: 'List of followers',
    hours: 24,
  },
  {
    label: 'Send message',
    name: 'social_network.followers',
    value: 'Send message',
    hours: 17,
  },
];

const postsFields = [
  {
    label: 'Texts and photos',
    name: 'social_network.posts',
    value: 'Texts and photos',
    hours: 32,
  },
  {
    label: 'Video posts',
    name: 'social_network.posts',
    value: 'Video posts',
    hours: 48,
  },
  {
    label: 'Access to posts by subscription',
    name: 'social_network.posts',
    value: 'Access to posts by subscription',
    hours: 8,
  },
  {
    label: 'Like/unlike/comments post',
    name: 'social_network.posts',
    value: 'Like/unlike/comments post',
    hours: 18,
  },
];

const SocialNetwork = () => {
  const { nextStep, formState, updateFormState, totalHours, activeStep } = useFormState();

  const form = useForm({ defaultValues: { social_network: formState.social_network } });

  const [isLoading, setLoading] = useState(false);

  const onSubmit = async ({ social_network }: any) => {
    try {
      setLoading(true);
      if (formState.id) {
        await api.strapi.updateFitnessAppEstimation(formState.id, {
          social_network: {
            friends: social_network.friends.join('\n'),
            posts: social_network.posts.join('\n'),
            followers: social_network.followers.join('\n'),
          },
          total_hours: totalHours.toString(),
          step: activeStep,
        });
      }
      updateFormState({ social_network });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...form}>
      <form>
        <div className={styles.container}>
          <h4 className={styles.subtitle}>Friends</h4>
          {friendsFields.map((item) => (
            <div key={item.value} className={styles.checkboxContainer}>
              <Checkbox {...item} />
            </div>
          ))}

          <h4 className={styles.subtitle}>Followers</h4>
          {followersFields.map((item) => (
            <div key={item.value} className={styles.checkboxContainer}>
              <Checkbox {...item} />
            </div>
          ))}

          <h4 className={styles.subtitle}>Posts</h4>
          {postsFields.map((item) => (
            <div key={item.value} className={styles.checkboxContainer}>
              <Checkbox {...item} />
            </div>
          ))}
        </div>
        <StepButtons onSubmit={onSubmit} isLoading={isLoading} />
      </form>
    </FormProvider>
  );
};

export default SocialNetwork;
