import React from 'react';
import { forms, IForm, Step } from './constants';
import { IFormState, StepperProvider } from './hooks/useFormState';

export const withStepperContext = (Component: React.FC) => () => {
  return (
    <StepperProvider>
      <Component />
    </StepperProvider>
  );
};

export const getFormDataByStep = (step: Step): IForm =>
  forms.find((item) => item.step === step) || ({} as IForm);

export const defaultValues = {
  OS: 'Cross-platform app',
  type: [],
  registration_and_authorization: [],
  user_profile: [],
  payments: {
    additional_features: [],
    enough_for_mvp: [],
  },
  fitness_club_management: [],
  social_network: {
    friends: [],
    followers: [],
    posts: [],
  },
  nutrition: [],
  workout_and_exercise: {
    workout_plan: [],
    workout_tracking: [],
    recording_results: '',
  },
  activity_tracking: [],
  additional_features: {
    notifications: [],
    messages: [],
    integrations: [],
    analitics: '',
  },
  additional_information: '',
  need_design: 'None',
  full_name: '',
  email: '',
};

export const createDefaultData = (data: any): IFormState => {
  return {
    id: data.id,
    OS: data.OS || 'Cross-platform app',
    type: data.type ? data.type.split('\n') : [],
    registration_and_authorization: data.registration_and_authorization
      ? data.registration_and_authorization.split('\n')
      : [],
    user_profile: data.user_profile ? data.user_profile.split('\n') : [],
    payments: {
      additional_features: data.payments?.additional_features
        ? data.payments.additional_features.split('\n')
        : [],
      enough_for_mvp: data.payments?.enough_for_mvp ? data.payments.enough_for_mvp.split('\n') : [],
    },
    fitness_club_management: data.fitness_club_management
      ? data.fitness_club_management.split('\n')
      : [],
    social_network: {
      friends: data.social_network?.friends ? data.social_network.friends.split('\n') : [],
      followers: data.social_network?.followers ? data.social_network.followers.split('\n') : [],
      posts: data.social_network?.posts ? data.social_network.posts.split('\n') : [],
    },
    nutrition: data.nutrition ? data.nutrition.split('\n') : [],
    workout_and_exercise: {
      workout_plan: data.workout_and_exercise?.workout_plan
        ? data.workout_and_exercise.workout_plan.split('\n')
        : [],
      workout_tracking: data.workout_and_exercise?.workout_tracking
        ? data.workout_and_exercise.workout_tracking.split('\n')
        : [],
      recording_results: data.recording_results || '',
    },
    activity_tracking: data.activity_tracking ? data.activity_tracking.split('\n') : [],
    additional_features: {
      notifications: data.additional_features?.notifications
        ? data.additional_features.notifications.split('\n')
        : [],
      messages: data.additional_features?.messages
        ? data.additional_features.messages.split('\n')
        : [],
      integrations: data.additional_features?.integrations
        ? data.additional_features.integrations.split('\n')
        : [],
      analitycs: data.additional_features?.analitycs || '',
    },
    additional_information: data.additional_information || '',
    need_design: data.need_design || 'None',
    full_name: data.full_name || '',
    email: data.email || '',
  };
};
