import React from 'react';
// utils
import clsx from 'clsx';
// styles
import classes from 'components/checkbox/checkbox.module.css';

const Checkbox = ({
  checked = false,
  onChange = () => {},
  ...rest
}: React.InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <div className={classes.container}>
      <input
        className={classes.hiddenCheckbox}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        {...rest}
      />
      <div className={clsx(classes.customCheckbox, { checked: checked })}>
        <div className={clsx(classes.checkIcon, { checked: checked })} />
      </div>
    </div>
  );
};

export default Checkbox;
