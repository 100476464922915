import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
// styles
import styles from '../common.module.css';
// components
import Checkbox from '../../checkbox';
import StepButtons from '../../step-buttons';
// hooks
import { useFormState } from 'components/fitness-app-calculator/hooks/useFormState';
import { api } from 'utils/api';

const fields = [
  {
    label: 'The application is used by visitors to enter the gym',
    name: 'fitness_club_management',
    value: 'The application is used by visitors to enter the gym',
    hours: 150,
  },
  {
    label: 'The app allows visitors to make purchases at the gym',
    name: 'fitness_club_management',
    value: 'The app allows visitors to make purchases at the gym',
    hours: 240,
  },
  {
    label: 'Club Membership Management',
    name: 'fitness_club_management',
    value: 'Club Membership Management',
    hours: 180,
  },
  {
    label: 'Management of prices and discounts',
    name: 'fitness_club_management',
    value: 'Management of prices and discounts',
    hours: 150,
  },
  {
    label: 'Marketing tools',
    name: 'fitness_club_management',
    value: 'Marketing tools',
    hours: 240,
  },
  {
    label: 'CRM',
    name: 'fitness_club_management',
    value: 'CRM',
    hours: 300,
  },
  {
    label: 'Staff management',
    name: 'fitness_club_management',
    value: 'Staff management',
    hours: 270,
  },
];

const FitnessClubManagement = () => {
  const { nextStep, formState, updateFormState, totalHours, activeStep } = useFormState();

  const form = useForm({
    defaultValues: { fitness_club_management: formState.fitness_club_management },
  });

  const [isLoading, setLoading] = useState(false);

  const onSubmit = async ({ fitness_club_management }: any) => {
    try {
      setLoading(true);
      if (formState.id) {
        await api.strapi.updateFitnessAppEstimation(formState.id, {
          fitness_club_management: fitness_club_management.join('\n'),
          total_hours: totalHours.toString(),
          step: activeStep,
        });
      }
      updateFormState({ fitness_club_management });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...form}>
      <form>
        <div className={styles.container}>
          {fields.map((item) => (
            <div key={item.value} className={styles.checkboxContainer}>
              <Checkbox {...item} />
            </div>
          ))}
        </div>
        <StepButtons onSubmit={onSubmit} isLoading={isLoading} />
      </form>
    </FormProvider>
  );
};

export default FitnessClubManagement;
