import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
// styles
import styles from './styles.module.css';
// components
import StepButtons from '../../step-buttons';
import { Radio } from '../need-design';
import { Step } from 'components/fitness-app-calculator/constants';
// hooks
import { useFormState } from 'components/fitness-app-calculator/hooks/useFormState';

const field = [
  {
    label: 'No',
    name: 'ableViewWorkout',
    value: 'No',
  },
  {
    label: 'Yes',
    name: 'ableViewWorkout',
    value: 'Yes',
  },
];

const AbleViewWorkout = () => {
  const { steps, formState, addStep, removeStep, updateFormState } = useFormState();

  const form = useForm({
    defaultValues: { ableViewWorkout: formState.ableViewWorkout },
  });

  const onSubmit = ({ ableViewWorkout }: any) => {
    updateFormState({ ableViewWorkout });
  };

  return (
    <FormProvider {...form}>
      <form>
        <div className={styles.container}>
          {field.map((item) => (
            <div key={item.value} className={styles.checkboxContainer}>
              <Radio
                {...item}
                onChange={(e) => {
                  if (e.target.value === 'Yes' && !steps.includes(Step.WorkoutAndExercise)) {
                    addStep(Step.WorkoutAndExercise, Step.AbleViewWorkout);
                  }
                  if (e.target.value === 'No' && steps.includes(Step.WorkoutAndExercise)) {
                    removeStep(Step.WorkoutAndExercise);
                  }
                  form.setValue('ableViewWorkout', e.target.value);
                }}
              />
            </div>
          ))}
        </div>
        <StepButtons onSubmit={onSubmit} />
      </form>
    </FormProvider>
  );
};

export default AbleViewWorkout;
