import React, { useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
// styles
import styles from '../common.module.css';
// components
import Checkbox from '../../checkbox';
import StepButtons from '../../step-buttons';
// hooks
import { useFormState } from 'components/fitness-app-calculator/hooks/useFormState';
import { api } from 'utils/api';

const fields = [
  {
    label: 'Profile with photo',
    name: 'user_profile',
    value: 'Profile with photo',
    hours: 16,
  },
  {
    label: 'Profile without photo',
    name: 'user_profile',
    value: 'Profile without photo',
    hours: 9,
  },
];

const UserProfile = () => {
  const { nextStep, updateFormState, formState, totalHours, activeStep } = useFormState();

  const form = useForm<{ user_profile: string[] }>({
    defaultValues: { user_profile: formState.user_profile },
  });

  const userProfileWatch = useWatch({
    name: 'user_profile',
    control: form.control,
    defaultValue: formState.user_profile,
  });

  const [isLoading, setLoading] = useState(false);

  const onSubmit = async ({ user_profile }: any) => {
    try {
      setLoading(true);
      if (formState.id) {
        await api.strapi.updateFitnessAppEstimation(formState.id, {
          user_profile: user_profile.join('\n'),
          total_hours: totalHours.toString(),
          step: activeStep,
        });
      }

      updateFormState({ user_profile });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...form}>
      <form>
        <div className={styles.container}>
          {fields.map((item) => (
            <div key={item.value} className={styles.checkboxContainer}>
              <Checkbox {...item} />
            </div>
          ))}
        </div>
        <StepButtons
          onSubmit={onSubmit}
          isLoading={isLoading}
          disableNextBtn={userProfileWatch.length === 0}
        />
      </form>
    </FormProvider>
  );
};

export default UserProfile;
