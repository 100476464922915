import React, { FC } from 'react';
// styles
import styles from './styles.module.css';
// constants
import { Step } from 'components/fitness-app-calculator/constants';
import Spinner from '../spinner';
// assets
import ArrowNext from './next.inline.svg';
import ArrowPrev from './prev.inline.svg';
// hooks
import { useFormState } from 'components/fitness-app-calculator/hooks/useFormState';
import { useFormContext } from 'react-hook-form';

const Button: FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = (props) => {
  return (
    <button {...props} className={styles.button}>
      {props.children}
    </button>
  );
};

interface IProps {
  disableNextBtn?: boolean;
  hideNextBtn?: boolean;
  isLoading?: boolean;
  onSubmit: (data: any) => Promise<void>;
}

const StepButtons: FC<IProps> = ({ disableNextBtn, hideNextBtn = false, isLoading, onSubmit }) => {
  const { steps, activeStep, prevStep, nextStep } = useFormState();

  const form = useFormContext();

  return (
    <div
      className={styles.container}
      style={{ justifyContent: activeStep === Step.OS ? 'flex-end' : 'space-between' }}
    >
      {activeStep !== Step.OS && (
        <Button
          type="button"
          disabled={isLoading}
          onClick={async () => {
            await form.handleSubmit(onSubmit)();
            prevStep();
          }}
        >
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <ArrowPrev />
              <span style={{ marginLeft: 5 }}>Previous</span>
            </>
          )}
        </Button>
      )}
      <div className={styles.steps}>
        {steps.indexOf(activeStep) + 1}/{steps.length}
      </div>
      {hideNextBtn || (
        <Button
          type="button"
          disabled={disableNextBtn || isLoading}
          onClick={async () => {
            await form.handleSubmit(onSubmit)();
            nextStep();
          }}
          style={{ justifySelf: 'end', backgroundColor: disableNextBtn ? '#ddd' : '#d91965' }}
        >
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <span style={{ marginRight: 5 }}>Next</span>
              <ArrowNext />
            </>
          )}
        </Button>
      )}
    </div>
  );
};

export default StepButtons;
